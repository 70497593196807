import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Notification } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/koros/customisation",
  "title": "Koros - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Notification type="alert" label="Koros customisation works differently!" className="siteNotification" mdxType="Notification">
  Koros is an older HDS component and therefore its customisation works slightly differently. Instead of the 
  <code>theme</code> property, you need to use the <code>style</code> property. This will be fixed in a future update.
    </Notification>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`In Core version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes in the HTML to customise the component.`}</p>
    <p>{`In React version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`className`}</inlineCode>{` properties to customise the component.`}</p>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Koros style={{ fill: 'var(--color-coat-of-arms)' }} />
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hds-koros" style="fill: var(--color-coat-of-arms);">
  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="100%" height="85">
    <defs>
      <pattern id="korosBasicCustom" x="0" y="0" width="106" height="85" patternUnits="userSpaceOnUse">
        <path transform="scale(5.3)" d="M0,800h20V0c-4.9,0-5,2.6-9.9,2.6S5,0,0,0V800z" />
      </pattern>
    </defs>
    <rect fill="url(#korosBasicCustom)" width="100%" height="85" />
  </svg>
</div>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      